import React from "react";

const Banner = () => {
  return (
    <>
      <div className="container-fluid banner-image"></div>
    </>
  );
};

export default Banner;
