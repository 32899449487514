import React from "react";

function GetDataComponent({ result }) {
  return (
    <div>
      {result.map((i, index) => (
        <div key={index} className="imagesize">
          <img src={i.image} alt="image" className="imagesize" />
        </div>
      ))}
    </div>
  );
}

export default GetDataComponent;
